<template>
  <div class="sessions-table" id="sessions-table">
    <vs-button
      v-tooltip="lang.metricSessions.download[languageSelected]"
      class="float-right"
      size
      radius
      type="flat"
      icon="icon-download"
      icon-pack="feather"
      @click="downloadAll"
    ></vs-button>
    <div class="vx-row">
      <vs-col vs-w="12">
        <h2 class="mb-0">
          {{ lang.metricSessions.title[languageSelected] }}
          <span v-if="total">({{ total }})</span>
        </h2>
      </vs-col>
    </div>
    <div class="vx-row">
      <vs-col vs-w="12">
        <div
          class="pt-4 pb-base vs-con-loading__container"
          :id="`div-with-loading-${report._id}`"
        >
          <vs-table
            :sst="true"
            :data="result"
            v-model="selected"
            search
            @search="handleSearch"
            @sort="handleSort"
            :max-items="LIMIT"
            ref="table"
            :key="key"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <!-- pagination
            @change-page="handleChangePage"
            :total="total"-->
            <template slot="thead">
              <vs-th
                :sort-key="sortKey(indexth)"
                :key="indexth"
                v-for="(_, indexth) in report._extra.th"
                >{{ th(indexth) }}</vs-th
              >
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :data="indextr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                class="bg-white"
              >
                <vs-td :data="data[indextr]._lastmessageat">
                  {{ data[indextr]._lastmessageat | moment('from', 'now') }}
                </vs-td>
                <vs-td :data="data[indextr].fullname">
                  <div class="vs-con-items float-left">
                    <vs-avatar
                      class="mr-4"
                      size="30px"
                      :src="data[indextr].picture"
                    />
                    <div>
                      <strong v-if="data[indextr].fullname">
                        {{ data[indextr].fullname }}
                      </strong>
                      <span v-else>
                        {{
                          lang.attentionRequests.sidenav.anonymousUser[
                            languageSelected
                          ]
                        }}
                      </span>
                    </div>
                  </div>
                </vs-td>
                <vs-td :data="data[indextr].platform">
                  <vs-chip>{{ data[indextr].platform }}</vs-chip>
                </vs-td>
                <vs-td :data="data[indextr].derived">
                  <vs-checkbox
                    disabled="true"
                    v-model="data[indextr].derived"
                  ></vs-checkbox>
                </vs-td>

                <vs-td :data="data[indextr].takeoverscount">
                  <vs-chip
                    v-if="data[indextr].takeoverscount !== 0"
                    color="primary"
                    >{{ data[indextr].takeoverscount }}</vs-chip
                  >
                  <vs-chip v-else>0</vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].messagescount">
                  {{ data[indextr].messagescount }}
                </vs-td>
                <template class="expand-session" slot="expand">
                  <div
                    class="
                      con-expand-session
                      w-full
                      bg-transparent
                      p-0
                      pt-4
                      pb-base
                    "
                    :key="`${currentPage}-expand-session`"
                  >
                    <vx-card slot="no-body" class="text-center">
                      <div>
                        <vs-row
                          vs-align="center"
                          vs-justify="center"
                          class="mb-4"
                        >
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h6>
                              {{ lang.metricSessions.agents[languageSelected] }}
                            </h6>
                            <div
                              class="vs-con-items"
                              v-for="(item, listindextr) in data[indextr]
                                .agentlist"
                              :key="listindextr"
                            >
                              <small>{{ item }}</small>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div ref="avatar">
                              <vs-avatar
                                size="45px"
                                :src="data[indextr].picture"
                              />
                            </div>
                            <div>
                              <strong v-if="data[indextr].fullname">
                                {{ data[indextr].fullname }}
                              </strong>
                              <span v-else>
                                {{
                                  lang.attentionRequests.sidenav.anonymousUser[
                                    languageSelected
                                  ]
                                }}
                              </span>
                            </div>
                            <div>
                              <span class="text-primary">
                                {{
                                  humanizeDur(
                                    new Date(data[indextr]._lastmessageat) -
                                      new Date(data[indextr]._firstmessageat)
                                  )
                                }}
                              </span>
                            </div>
                            <div>
                              <small>
                                {{
                                  data[indextr]._firstmessageat
                                    | moment('DD-MM-YYYY, h:mm:ss A')
                                }}
                              </small>
                              /
                              <small>
                                {{
                                  data[indextr]._lastmessageat
                                    | moment('DD-MM-YYYY, h:mm:ss A')
                                }}
                              </small>
                            </div>
                            <vs-row
                              vs-type="flex"
                              vs-justify="center"
                              class="mt-4"
                            >
                              <vs-col
                                vs-w="6"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vs-button
                                  size="small"
                                  radius
                                  type="border"
                                  icon="icon-arrow-up-right"
                                  icon-pack="feather"
                                  color="success"
                                  @click="
                                    changeView(
                                      data[indextr].chat,
                                      data[indextr].sessionId,
                                      data[indextr]._firstmessageid,
                                      data[indextr].service
                                    )
                                  "
                                ></vs-button>
                              </vs-col>
                              <vs-col
                                vs-w="6"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vs-button
                                  size="small"
                                  radius
                                  type="border"
                                  icon="icon-download"
                                  color="success"
                                  icon-pack="feather"
                                  @click="download(data[indextr])"
                                ></vs-button>
                              </vs-col>
                            </vs-row>
                            <vs-divider />
                          </vs-col>

                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h6>
                              {{ lang.metricSessions.tags[languageSelected] }}
                            </h6>
                            <div
                              class="vs-con-items"
                              v-for="(item, listindextr) in data[indextr]
                                .taglist"
                              :key="listindextr"
                            >
                              <vs-chip :color="infoColor">{{ item }}</vs-chip>
                            </div>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-align="center"
                          vs-justify="center"
                          class="mb-4"
                        >
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h6 class="mb-4">
                              {{
                                lang.metricSessions.agentMessages[
                                  languageSelected
                                ]
                              }}
                            </h6>
                            <strong class="text-primary">
                              {{ data[indextr].agentmessagescount }}
                            </strong>
                          </vs-col>
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h6 class="mb-4">
                              {{
                                lang.metricSessions.userMessages[
                                  languageSelected
                                ]
                              }}
                            </h6>
                            <strong class="text-primary">
                              {{ data[indextr].usermessagescount }}
                            </strong>
                          </vs-col>
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h6 class="mb-4">
                              {{
                                lang.metricSessions.botResponses[
                                  languageSelected
                                ]
                              }}
                            </h6>
                            <strong class="text-primary">
                              {{ data[indextr].botmessagescount }}
                            </strong>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-align="center"
                          vs-justify="center"
                          v-if="
                            data[indextr].messagescountbyagent &&
                            data[indextr].messagescountbyagent.length > 0
                          "
                        >
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="text-center"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <div
                                v-for="(item, index) in data[indextr]
                                  .messagescountbyagent"
                                :key="index"
                                vs-justify="center"
                                vs-align="center"
                                class="text-center"
                              >
                                <small class>
                                  <span>{{ item.name }}</span
                                  >,
                                  <span>{{ item.email }}</span>
                                  <br />
                                  <span>({{ item.count }})</span>
                                </small>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          ></vs-col>
                          <vs-col
                            vs-w="4"
                            class="text-center"
                            vs-justify="center"
                            vs-align="center"
                          ></vs-col>
                        </vs-row>
                      </div>
                    </vx-card>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            class="mt-2 float-right"
            v-if="total"
            :total="Math.ceil(total / LIMIT)"
            v-model="currentPage"
            @change="handleChangePage"
          />
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import konecta from '@/services/konecta'
import jsPDF from 'jspdf'
const moment = require('moment')
const Json2csvParser = require('json2csv').Parser
const emojiStrip = require('emoji-strip')
const humanizeDuration = require('humanize-duration')

export default {
  name: 'SessionsTableV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    interval: {
      type: Object,
      required: true
    },
    services: {
      type: Array,
      required: true
    },
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VsTable: () => import('../../../../../components/vx-table/vsTable'),
    VsTh: () => import('../../../../../components/vx-table/vsTh'),
    VsTr: () => import('../../../../../components/vx-table/vsTr'),
    VsTd: () => import('../../../../../components/vx-table/vsTd')
  },
  data: () => ({
    Json2csvParser,
    infoColor: '#4b7aff',
    LIMIT: 25, // cantidad de elementos por página
    key: new Date().getTime(),
    selected: [],
    result: [],
    total: null,
    currentPage: 1,
    opened: [],
    orderByColumn: '_lastMessageAt',
    orderByOrder: 'desc',
    search: '',
    searchTimeout: null,
    lastSearch: '',
    searchAwait: 700,
    fullnameLike: ''
  }),
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  watch: {
    services() {
      this.reset()
      this.load(this.report, this.LIMIT, 0)
    },
    interval() {
      this.reset()
      this.load(this.report, this.LIMIT, 0)
    },
    versions() {
      this.reset()
      this.load(this.report, this.LIMIT, 0)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    reset() {
      this.result = []
      this.total = 0
      this.currentPage = 1

      this.orderByColumn = '_lastMessageAt'
      this.orderByOrder = 'desc'

      this.search = ''
      this.searchTimeout = null
      this.lastSearch = ''
      this.searchAwait = 700

      this.fullnameLike = ''
    },
    takeoversCount(takeovers, session) {
      return takeovers.filter(
        item => item.sessionId && item.sessionId === session
      ).length
    },
    changeView(chat, session, message, bot) {
      let routeData = this.$router.resolve({
        path: '/attention-requests',
        query: {
          chat,
          session,
          message,
          bot
        }
      })

      window.open(routeData.href, '_blank')
    },
    async downloadAll() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.interval || !this.interval.from || !this.interval.to) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const payload = {
        fullnameLike: this.fullnameLike,
        orderByColumn: this.orderByColumn,
        orderByOrder: this.orderByOrder,
        limit: 1000000,
        offset: 0,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr
      }
      this.$vs.loading({
        container: `#div-with-loading-${this.report._id}`,
        scale: 1.0
      })

      try {
        let response = await konecta.get(
          '/report/5f60f5cb8485171ab346cdf6?select[name]=true&select[description]=true&select[reportType]=true&select[_extra]=true'
        )
        let reportV2 = response.data
        const result = await konecta.post(
          `/report/query/${reportV2._id}`,
          payload
        )
        const aux = result.data[0]
        const aux2 = result.data[1]
        let fields = reportV2._extra.generalFields.map((item, index) =>
          this.th2(reportV2, index)
        )

        let data = []
        let maxAgents = 0
        aux.forEach(item => {
          data.push({})
          const route = this.$router.resolve({
            path: '/attention-requests',
            query: {
              chat: item.chat,
              session: item.sessionId,
              message: item._firstmessageid,
              bot: item.service
            }
          })

          const link = window.location.origin + route.href
          data[data.length - 1][fields[0]] = item.sessionId
          data[data.length - 1][fields[1]] = link
          data[data.length - 1][fields[2]] = item.senderId
          data[data.length - 1][fields[3]] = item.platform
          data[data.length - 1][fields[4]] = item.botname
          data[data.length - 1][fields[5]] = item.tag
          data[data.length - 1][fields[6]] = item.fullname
          data[data.length - 1][fields[7]] = item.firstmessage
          data[data.length - 1][fields[8]] = item.lastusermessage
          if (item.lastusermessage != null && item.lastbotmessage != null) {
            data[data.length - 1][fields[9]] = 'Bot'
          } else if (item.lastusermessage != null) {
            data[data.length - 1][fields[9]] = 'User'
          }
          data[data.length - 1][fields[10]] = item.firstcondition
          data[data.length - 1][fields[11]] = item.cantusermessages
          data[data.length - 1][fields[12]] = item.cantuserbotmessages
          data[data.length - 1][fields[13]] = item.cantuserconditionsnotakeover
          if (item.conditions != null) {
            data[data.length - 1][fields[14]] = item.conditions.join(', ')
          } else {
            data[data.length - 1][fields[14]] = item.conditions
          }
          data[data.length - 1][fields[15]] = item.lastcondition
          data[data.length - 1][fields[16]] = item.tags
          data[data.length - 1][fields[17]] = item.cantnotdetected
          data[data.length - 1][fields[18]] = item.takeovercondition
          data[data.length - 1][fields[19]] = item.derivationhour
          data[data.length - 1][fields[20]] = item.browser
          data[data.length - 1][fields[21]] = item.os
          let takeovers = aux2.filter(obj => {
            return obj.sessionId === item.sessionId
          })
          if (takeovers.length > 0) {
            let a = 1
            let nexti = 0
            let cantFields = 8
            for (var i = 0; i < takeovers[0].waittimes.length; i++) {
              if (a > maxAgents) {
                maxAgents++
                let newFields = reportV2._extra.agentsFields.map(
                  (item, index) => this.th3(reportV2, index, i + 1)
                )
                fields = fields.concat(newFields)
              }

              if (i == 0) {
                if (item.derivationhour != null) {
                  data[data.length - 1][fields[22 + cantFields * nexti]] =
                    this.humanizeDur(
                      new Date(takeovers[0].createdtimes[i]) -
                        new Date(item.derivationhour)
                    )
                }
              } else {
                data[data.length - 1][fields[22 + cantFields * nexti]] =
                  this.humanizeDur(
                    new Date(takeovers[0].createdtimes[i]) -
                      new Date(takeovers[0].finishedtimes[i - 1])
                  )
              }
              data[data.length - 1][fields[23 + cantFields * nexti]] =
                takeovers[0].createdtimes[i]
              data[data.length - 1][fields[24 + cantFields * nexti]] =
                this.humanizeDur(takeovers[0].waittimes[i] * 1000)
              data[data.length - 1][fields[25 + cantFields * nexti]] =
                takeovers[0].users? takeovers[0].users[i]: ''
              data[data.length - 1][fields[26 + cantFields * nexti]] =
                takeovers[0].firstmessages[i]
              if (
                i === takeovers[0].waittimes.length - 1 &&
                takeovers[0].finished
              ) {
                data[data.length - 1][fields[27 + cantFields * nexti]] = ''
                if (
                  takeovers[0].finishedtimes != null &&
                  i < takeovers[0].finishedtimes.length
                ) {
                  data[data.length - 1][fields[28 + cantFields * nexti]] =
                    takeovers[0].finishedtimes[i]
                }
              } else {
                if (
                  takeovers[0].finishedtimes != null &&
                  i < takeovers[0].finishedtimes.length
                ) {
                  data[data.length - 1][fields[27 + cantFields * nexti]] =
                    takeovers[0].finishedtimes[i]
                }
                data[data.length - 1][fields[28 + cantFields * nexti]] = ''
              }
              if (
                takeovers[0].totaltimes != null &&
                i < takeovers[0].totaltimes.length
              ) {
                data[data.length - 1][fields[29 + cantFields * nexti]] =
                  this.humanizeDur(takeovers[0].totaltimes[i] * 1000)
              }
              a++
              nexti++
            }
          }

          //   const route = this.$router.resolve({
          //     path: '/attention-requests',
          //     query: {
          //       chat: item.chat,
          //       session: item.sessionid,
          //       message: item._firstmessageid
          //     }
          //   })
          //   if (item.tags != null) item.tags = item.tags.replace(/\"/g, ' ')

          //   const link = window.location.origin + route.href
          //   data[data.length - 1][fields[9]] = item.tags
          //   data[data.length - 1][fields[10]] = link
        })

        const json2csvParser = new Json2csvParser({ fields })
        const csv = json2csvParser.parse(data)

        let filename = `konecta_metric_${this.title}_`
        filename += new Date().toISOString()

        var BOM = '\uFEFF'
        var csvData = BOM + csv

        var blob = new Blob([csvData], {
          type: 'text/plain; encoding=utf-8'
        })

        saveData(blob, `${filename}.csv`)
        this.$vs.loading.close(
          `#div-with-loading-${this.report._id} > .con-vs-loading`
        )
      } catch (ex) {
        this.$vs.loading.close(
          `#div-with-loading-${this.report._id} > .con-vs-loading`
        )
        this.$vs.notify({
          title: 'Error',
          text: '',
          color: 'danger',
          position: 'top-right'
        })
        console.error(ex)
      }
    },
    async download(session) {
      var doc = new jsPDF()

      let pic_height

      if (session.picture) {
        const el = this.$refs['avatar'][0]

        const options = {
          type: 'dataURL'
        }
        const output = await this.$html2canvas(el, options)

        const width = doc.internal.pageSize.getWidth()

        const componentWidth = this.$refs['avatar'][0].clientWidth
        const percent = (width * 100) / componentWidth
        const height = this.$refs['avatar'][0].clientHeight * percent * 0.01
        pic_height = height

        doc.addImage(output, 'PNG', 0, 10, width, height)
      }

      let name =
        session.fullname ||
        this.lang.attentionRequests.sidenav.anonymousUser[this.languageSelected]

      name = emojiStrip(name)

      const route = this.$router.resolve({
        path: '/attention-requests',
        query: {
          chat: session.chat,
          session: session.sessionId,
          message: session._firstmessageid,
          bot: session.service
        }
      })

      const link = window.location.origin + route.href

      doc.fromHTML(
        `
          <h1>
            ${name}
          </h1>
          <p>
            ${
              this.lang.metricSessions.export.platform[this.languageSelected]
            }: ${session.platform}
          </p>
          <hr/>
          <p>
          ${
            this.lang.metricSessions.export.duration[this.languageSelected]
          }: ${this.$moment
          .duration(
            new Date(session._lastmessageat) - new Date(session._firstmessageat)
          )
          .humanize()}
          </p>
          <p>
          ${this.$moment(new Date(session._firstmessageat)).format(
            'DD-MM-YYYY, h:mm:ss A'
          )} / ${this.$moment(new Date(session._lastmessageat)).format(
          'DD-MM-YYYY, h:mm:ss A'
        )}
          </p>
          <br/>
          <br/>
          <br/>
          <p>
          ${this.lang.metricSessions.export.derived[this.languageSelected]}: ${
          session.derived
            ? this.lang.metricSessions.export.yes[this.languageSelected]
            : this.lang.metricSessions.export.no[this.languageSelected]
        }
          </p>
          <p>
          ${
            this.lang.metricSessions.export.takeovers[this.languageSelected]
          }: ${session.takeoverscount}
          </p>
          <strong>
          ${this.lang.metricSessions.export.messages[this.languageSelected]} (${
          session.messagescount
        })
          </strong>
          <ul>
          <li>
          ${this.lang.metricSessions.agentMessages[this.languageSelected]}: ${
          session.agentmessagescount
        }
          </li>
          <li>
          ${this.lang.metricSessions.userMessages[this.languageSelected]}: ${
          session.usermessagescount
        }
          </li>
          <li>
          ${this.lang.metricSessions.botResponses[this.languageSelected]}: ${
          session.botmessagescount
        }
          </li>
          </ul>
        `,
        10,
        (pic_height || 0) + 10
      )
      if (session.agents != null) {
        session.agents = session.agents.replace(/\,/g, ' , ')
      }
      if (session.tags != null) {
        session.tags = session.tags.replace(/\"/g, ' ')
      }
      doc.fromHTML(
        `
        <strong>
          ${this.lang.metricSessions.agents[this.languageSelected]}: 
        </strong> ${session.agents}`,
        10,
        110
      )
      doc.fromHTML(
        `
        <strong>
          ${this.lang.metricSessions.tags[this.languageSelected]}:
        </strong> ${session.tags}`,
        10,
        120
      )

      doc.textWithLink('Link', 10, doc.internal.pageSize.getHeight() - 120, {
        url: link
      })

      doc.save(`session_${session.sessionId}.pdf`)
    },
    th(index) {
      if (!this.report || !this.report._extra || !this.report._extra.th) {
        return 'N/A'
      }
      if (this.report._extra.th[index]) {
        const found = this.report._extra.th[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'N/A'
    },
    th2(report, index) {
      if (!report || !report._extra || !report._extra.generalFields) {
        return 'N/A'
      }
      if (report._extra.generalFields[index]) {
        const found = report._extra.generalFields[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'N/A'
    },
    th3(report, index, agentCount) {
      if (!report || !report._extra || !report._extra.agentsFields) {
        return 'N/A'
      }
      if (report._extra.agentsFields[index]) {
        const found = report._extra.agentsFields[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text + '(' + agentCount + ')'
        }
      }
      return 'N/A'
    },
    humanizeDur(aux) {
      return humanizeDuration(aux, {
        round: true,
        largest: 2,
        language: this.languageSelected
      })
    },
    sortKey(index) {
      if (!this.report || !this.report._extra || !this.report._extra.th) {
        return undefined
      }
      if (this.report._extra.th[index]) {
        const found = this.report._extra.th[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.sortKey
        }
      }
      return undefined
    },
    handleSort(column, order) {
      if (!order) {
        return
      }
      this.orderByColumn = column
      this.orderByOrder = order
      this.load(this.report, this.LIMIT, 0, this.currentPage !== 1)
    },
    handleChangePage(page) {
      // quitamos los elementos que estaban abiertos, para que no se rompa
      this.$el.querySelectorAll('.tr-expand').forEach(e => {
        if (e.parentNode) {
          e.parentNode.removeChild(e)
        }
      })
      this.currentPage = page
      // if (Math.ceil(this.result.length / this.LIMIT) <= page) {
      // // if (Math.ceil(this.total / this.LIMIT) >= page) {
      //   this.load(this.report, this.LIMIT, this.result.length)
      //   // this.load(this.report, this.LIMIT, this.LIMIT * (page - 1))
      // } else {
      //   // workaround para hacer que actualize cuando vamos hacia atrás
      //   const aux = this.result
      //   this.result = []
      //   this.$nextTick(() => {
      //     this.result = aux
      //     this.currentPage = page
      //   })
      // }
      this.load(this.report, this.LIMIT, this.LIMIT * (page - 1))
    },
    handleSearch(arg1) {
      this.search = (arg1 || '').trim()
      if (this.search !== this.lastSearch) {
        this.searchTimeout = new Date(Date.now() + this.searchAwait)
      } else {
        this.searchTimeout = null
      }
    },
    async load(report, limit, offset, reset) {
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.interval || !this.interval.from || !this.interval.to) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        fullnameLike: this.fullnameLike,
        orderByColumn: this.orderByColumn,
        orderByOrder: this.orderByOrder,
        limit,
        offset,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        if (reset) {
          this.key = new Date().getTime()
          this.currentPage = 1
        }
        if (result.data[0] != null && result.data[0].length > 0) {
          result.data[0].forEach(item => {
            if (item.agents != null) item.agentlist = item.agents.split(',')
            if (item.tags != null)
              item.taglist = item.tags.replace(/\"/g, '').split(',')
          })
        }
        this.result = result.data[0]
        this.key = new Date().getTime()
        this.total = parseInt(result.data[1][0].count)
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  async mounted() {
    setInterval(() => {
      if (this.searchTimeout && this.searchTimeout < new Date()) {
        this.searchTimeout = null
        this.lastSearch = this.search
        this.fullnameLike = this.search
        this.load(this.report, this.LIMIT, 0, this.currentPage !== 1)
      }
    }, 200)
  }
}
</script>

<style>
.vs-table--tbody {
  z-index: 1 !important;
  min-width: 0% !important;
}

.vs-table--tbody-table {
  min-width: 0% !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.sessions-table {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }

  .vs-pagination--buttons,
  .item-pagination {
    width: 30px;
    height: 30px;
  }
  .item-pagination {
    font-size: 0.8rem;
  }

  .con-vs-pagination {
    padding: 7px;
  }
}
</style>
