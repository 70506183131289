var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sessions-table",attrs:{"id":"sessions-table"}},[_c('vs-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.lang.metricSessions.download[_vm.languageSelected]),expression:"lang.metricSessions.download[languageSelected]"}],staticClass:"float-right",attrs:{"size":"","radius":"","type":"flat","icon":"icon-download","icon-pack":"feather"},on:{"click":_vm.downloadAll}}),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("\n        "+_vm._s(_vm.lang.metricSessions.title[_vm.languageSelected])+"\n        "),(_vm.total)?_c('span',[_vm._v("("+_vm._s(_vm.total)+")")]):_vm._e()])])],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('div',{staticClass:"pt-4 pb-base vs-con-loading__container",attrs:{"id":("div-with-loading-" + (_vm.report._id))}},[_c('vs-table',{key:_vm.key,ref:"table",attrs:{"sst":true,"data":_vm.result,"search":"","max-items":_vm.LIMIT,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},on:{"search":_vm.handleSearch,"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,staticClass:"bg-white",attrs:{"data":indextr}},[_c('vs-td',{attrs:{"data":data[indextr]._lastmessageat}},[_vm._v("\n                "+_vm._s(_vm._f("moment")(data[indextr]._lastmessageat,'from', 'now'))+"\n              ")]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].fullname}},[_c('div',{staticClass:"vs-con-items float-left"},[_c('vs-avatar',{staticClass:"mr-4",attrs:{"size":"30px","src":data[indextr].picture}}),_vm._v(" "),_c('div',[(data[indextr].fullname)?_c('strong',[_vm._v("\n                      "+_vm._s(data[indextr].fullname)+"\n                    ")]):_c('span',[_vm._v("\n                      "+_vm._s(_vm.lang.attentionRequests.sidenav.anonymousUser[
                          _vm.languageSelected
                        ])+"\n                    ")])])],1)]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].platform}},[_c('vs-chip',[_vm._v(_vm._s(data[indextr].platform))])],1),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].derived}},[_c('vs-checkbox',{attrs:{"disabled":"true"},model:{value:(data[indextr].derived),callback:function ($$v) {_vm.$set(data[indextr], "derived", $$v)},expression:"data[indextr].derived"}})],1),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].takeoverscount}},[(data[indextr].takeoverscount !== 0)?_c('vs-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(data[indextr].takeoverscount))]):_c('vs-chip',[_vm._v("0")])],1),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].messagescount}},[_vm._v("\n                "+_vm._s(data[indextr].messagescount)+"\n              ")]),_vm._v(" "),_c('template',{staticClass:"expand-session",slot:"expand"},[_c('div',{key:(_vm.currentPage + "-expand-session"),staticClass:"\n                    con-expand-session\n                    w-full\n                    bg-transparent\n                    p-0\n                    pt-4\n                    pb-base\n                  "},[_c('vx-card',{staticClass:"text-center",attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',[_c('vs-row',{staticClass:"mb-4",attrs:{"vs-align":"center","vs-justify":"center"}},[_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('h6',[_vm._v("\n                            "+_vm._s(_vm.lang.metricSessions.agents[_vm.languageSelected])+"\n                          ")]),_vm._v(" "),_vm._l((data[indextr]
                              .agentlist),function(item,listindextr){return _c('div',{key:listindextr,staticClass:"vs-con-items"},[_c('small',[_vm._v(_vm._s(item))])])})],2),_vm._v(" "),_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('div',{ref:"avatar",refInFor:true},[_c('vs-avatar',{attrs:{"size":"45px","src":data[indextr].picture}})],1),_vm._v(" "),_c('div',[(data[indextr].fullname)?_c('strong',[_vm._v("\n                              "+_vm._s(data[indextr].fullname)+"\n                            ")]):_c('span',[_vm._v("\n                              "+_vm._s(_vm.lang.attentionRequests.sidenav.anonymousUser[
                                  _vm.languageSelected
                                ])+"\n                            ")])]),_vm._v(" "),_c('div',[_c('span',{staticClass:"text-primary"},[_vm._v("\n                              "+_vm._s(_vm.humanizeDur(
                                  new Date(data[indextr]._lastmessageat) -
                                    new Date(data[indextr]._firstmessageat)
                                ))+"\n                            ")])]),_vm._v(" "),_c('div',[_c('small',[_vm._v("\n                              "+_vm._s(_vm._f("moment")(data[indextr]._firstmessageat,'DD-MM-YYYY, h:mm:ss A'))+"\n                            ")]),_vm._v("\n                            /\n                            "),_c('small',[_vm._v("\n                              "+_vm._s(_vm._f("moment")(data[indextr]._lastmessageat,'DD-MM-YYYY, h:mm:ss A'))+"\n                            ")])]),_vm._v(" "),_c('vs-row',{staticClass:"mt-4",attrs:{"vs-type":"flex","vs-justify":"center"}},[_c('vs-col',{attrs:{"vs-w":"6","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vs-button',{attrs:{"size":"small","radius":"","type":"border","icon":"icon-arrow-up-right","icon-pack":"feather","color":"success"},on:{"click":function($event){_vm.changeView(
                                    data[indextr].chat,
                                    data[indextr].sessionId,
                                    data[indextr]._firstmessageid,
                                    data[indextr].service
                                  )}}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-w":"6","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vs-button',{attrs:{"size":"small","radius":"","type":"border","icon":"icon-download","color":"success","icon-pack":"feather"},on:{"click":function($event){_vm.download(data[indextr])}}})],1)],1),_vm._v(" "),_c('vs-divider')],1),_vm._v(" "),_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('h6',[_vm._v("\n                            "+_vm._s(_vm.lang.metricSessions.tags[_vm.languageSelected])+"\n                          ")]),_vm._v(" "),_vm._l((data[indextr]
                              .taglist),function(item,listindextr){return _c('div',{key:listindextr,staticClass:"vs-con-items"},[_c('vs-chip',{attrs:{"color":_vm.infoColor}},[_vm._v(_vm._s(item))])],1)})],2)],1),_vm._v(" "),_c('vs-row',{staticClass:"mb-4",attrs:{"vs-align":"center","vs-justify":"center"}},[_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('h6',{staticClass:"mb-4"},[_vm._v("\n                            "+_vm._s(_vm.lang.metricSessions.agentMessages[
                                _vm.languageSelected
                              ])+"\n                          ")]),_vm._v(" "),_c('strong',{staticClass:"text-primary"},[_vm._v("\n                            "+_vm._s(data[indextr].agentmessagescount)+"\n                          ")])]),_vm._v(" "),_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('h6',{staticClass:"mb-4"},[_vm._v("\n                            "+_vm._s(_vm.lang.metricSessions.userMessages[
                                _vm.languageSelected
                              ])+"\n                          ")]),_vm._v(" "),_c('strong',{staticClass:"text-primary"},[_vm._v("\n                            "+_vm._s(data[indextr].usermessagescount)+"\n                          ")])]),_vm._v(" "),_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('h6',{staticClass:"mb-4"},[_vm._v("\n                            "+_vm._s(_vm.lang.metricSessions.botResponses[
                                _vm.languageSelected
                              ])+"\n                          ")]),_vm._v(" "),_c('strong',{staticClass:"text-primary"},[_vm._v("\n                            "+_vm._s(data[indextr].botmessagescount)+"\n                          ")])])],1),_vm._v(" "),(
                          data[indextr].messagescountbyagent &&
                          data[indextr].messagescountbyagent.length > 0
                        )?_c('vs-row',{attrs:{"vs-align":"center","vs-justify":"center"}},[_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('div',{staticClass:"text-center",attrs:{"vs-justify":"center","vs-align":"center"}},_vm._l((data[indextr]
                                .messagescountbyagent),function(item,index){return _c('div',{key:index,staticClass:"text-center",attrs:{"vs-justify":"center","vs-align":"center"}},[_c('small',{},[_c('span',[_vm._v(_vm._s(item.name))]),_vm._v(",\n                                "),_c('span',[_vm._v(_vm._s(item.email))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',[_vm._v("("+_vm._s(item.count)+")")])])])}))]),_vm._v(" "),_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}}),_vm._v(" "),_c('vs-col',{staticClass:"text-center",attrs:{"vs-w":"4","vs-justify":"center","vs-align":"center"}})],1):_vm._e()],1)])],1)])],2)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"thead"},_vm._l((_vm.report._extra.th),function(_,indexth){return _c('vs-th',{key:indexth,attrs:{"sort-key":_vm.sortKey(indexth)}},[_vm._v(_vm._s(_vm.th(indexth)))])}),1)],2),_vm._v(" "),(_vm.total)?_c('vs-pagination',{staticClass:"mt-2 float-right",attrs:{"total":Math.ceil(_vm.total / _vm.LIMIT)},on:{"change":_vm.handleChangePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }